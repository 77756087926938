import React from 'react';

import Layout from '../../components/MenuLayout';
import { Heading } from '../../components/Common/styled/Heading';

export default () => (
  <Layout>
    <Heading>Create new study</Heading>
  </Layout>
);
